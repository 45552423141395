import Backend from '@/core/Services/Backend';
import { ref, onBeforeUnmount } from 'vue';
import axios from 'axios';
/**
 * 轮询查询接口
 * TODO 改成promise
 * @returns
 */
const useQrResult = (onSuccess) => {
    const timer = ref(0);
    const cancelSource = ref(axios.CancelToken.source());
    const startFetchResult = async (id) => {
        cancelSource.value = axios.CancelToken.source();
        try {
            const { state, hash, chainType } = await Backend.getQrResult(id, cancelSource.value.token);
            // TODO 删掉state===2
            if (state === '1' || state === '2') {
                onSuccess(hash, chainType);
            }
            timer.value = window.setTimeout(() => {
                startFetchResult(id);
            }, 1000);
        }
        catch (e) {
            return null;
        }
        return null;
    };
    onBeforeUnmount(() => {
        if (timer.value) {
            window.clearTimeout(timer.value);
        }
        cancelSource.value.cancel();
    });
    return {
        startFetchResult,
    };
};
export default useQrResult;
