import { defineComponent } from 'vue';
export default defineComponent({
    name: 'Switch',
    props: {
        modelValue: {
            type: Boolean,
            required: true,
        },
    },
    emits: ['update:modelValue'],
    setup(props, { emit }) {
        const switchValue = () => {
            emit('update:modelValue', !props.modelValue);
        };
        return {
            switchValue,
        };
    },
});
