import { defineComponent } from 'vue';
export default defineComponent({
    name: 'BaseRadio',
    props: {
        label: {
            type: String,
            required: true,
        },
        modelValue: {
            // 当前选中的value
            type: [String, Number],
            required: true,
        },
        value: {
            type: String,
            required: true,
        },
    },
    emits: ['update:modelValue', 'change'],
    setup(props, { emit }) {
        const clickHandler = () => {
            if (props.modelValue === props.value) {
                return;
            }
            emit('update:modelValue', props.value);
            emit('change', props.value);
        };
        return {
            clickHandler,
        };
    },
});
