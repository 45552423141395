import request from '@/core/utils/request';
const getApproveStatus = async (chainType, txHash) => {
    const { data } = await request({
        method: 'get',
        url: '/common/transaction/state',
        params: {
            chainType,
            txHash,
        },
    });
    return data;
};
export default getApproveStatus;
