import { reactive, toRefs } from 'vue';
const useDialog = () => {
    const state = reactive({
        isVisible: false,
        selectedTarget: null,
        selectedIndex: 0,
    });
    const showDialog = (selectedTarget, index) => {
        state.isVisible = true;
        if (selectedTarget) {
            state.selectedTarget = selectedTarget;
        }
        if (index) {
            state.selectedIndex = index;
        }
    };
    const closeDialog = () => {
        state.isVisible = false;
        state.selectedTarget = null;
        state.selectedIndex = 0;
    };
    return {
        ...toRefs(state),
        showDialog,
        closeDialog,
    };
};
export default useDialog;
