import request from '@/core/utils/request';
/**
 * @description: 获取授权的gas
 * @param {string} tokenHash
 * @param {number} amount
 * @param {string} chainType
 * @return {*}
 */
const getApproveGas = async (tokenHash, amount, chainType, isNft = false) => {
    const { data } = await request({
        url: '/airdrop/approve-gas',
        method: 'post',
        data: {
            tokenHash,
            amount,
            chainType,
            isNft,
        },
    });
    return data.result;
};
export default getApproveGas;
