import { defineComponent } from 'vue';
export default defineComponent({
    name: 'BaseTableCell',
    props: {
        row: {
            // 行数据
            type: Object,
            required: true,
        },
        col: {
            // 列
            type: Object,
            required: true,
        },
        index: {
            // 行序号
            type: Number,
            required: true,
        },
    },
    render() {
        return (<td>
        {this.col.renderFunc
            ? this.col.renderFunc({
                row: this.row,
                $index: this.index,
            })
            : this.row[this.col.prop]}
      </td>);
    },
});
