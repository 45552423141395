import getApproveStatus from '@/core/Services/Backend/getApproveStatus';
const pollApproveStatus = (chainType, txHash) => new Promise((resolve) => {
    const timer = window.setInterval(() => {
        getApproveStatus(chainType, txHash).then((res) => {
            if (res.result === '0' || res.result === '1') {
                window.clearInterval(timer);
                resolve(res.result);
            }
        });
    }, 2000);
});
export default pollApproveStatus;
