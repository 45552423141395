import BaseTableCell from '@/components/BaseTable/BaseTableCell';
import { defineComponent } from 'vue';
export default defineComponent({
    name: 'BaseTable',
    components: { BaseTableCell },
    props: {
        data: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            cols: [],
        };
    },
});
