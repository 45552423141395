import { ChaintoFullname } from '@/core/types/enums';
import { openExplorerForAddress } from '@/core/utils/openExplorer';
import { computed, defineComponent } from 'vue';
export default defineComponent({
    name: 'ContractAddress',
    components: {},
    props: {
        chainType: {
            required: true,
            type: String,
        },
        type: {
            required: false,
            type: String,
            default: 'drop',
        },
    },
    setup(props) {
        const contractAddress = computed(() => {
            if (props.type === 'drop') {
                switch (props.chainType) {
                    case 'eth':
                        return '0x8f448b0d19d9d31b3f860b11c2835e98704180a4';
                    case 'bsc':
                        return '0x8f448b0d19d9d31b3f860b11c2835e98704180a4';
                    case 'polygon':
                        return '0xe9a8b3c3ece50f4f3bde72ec62bd25b1c2d6e3c2';
                    case 'okex':
                        return '0x8f448b0d19d9d31b3f860b11c2835e98704180a4';
                    default:
                        return '';
                }
            }
            else if (props.type === 'nft') {
                switch (props.chainType) {
                    case 'eth':
                        return '0xB659380C1909C7738a714B11e4Aa04453e2A2276';
                    case 'bsc':
                        return '0xB659380C1909C7738a714B11e4Aa04453e2A2276';
                    case 'polygon':
                        return '0xB659380C1909C7738a714B11e4Aa04453e2A2276';
                    case 'okex':
                        return '0xB659380C1909C7738a714B11e4Aa04453e2A2276';
                    default:
                        return '';
                }
            }
            else {
                return '';
            }
        });
        const chainFullName = computed(() => {
            switch (props.chainType) {
                case 'eth':
                    return ChaintoFullname.eth;
                case 'bsc':
                    return ChaintoFullname.bsc;
                case 'polygon':
                    return ChaintoFullname.polygon;
                case 'okex':
                    return ChaintoFullname.okex;
                default:
                    return '';
            }
        });
        return {
            contractAddress,
            ChaintoFullname,
            chainFullName,
            openExplorerForAddress,
        };
    },
});
