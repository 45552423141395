import { defineComponent, onMounted, ref } from 'vue';
import QrView from '@/components/QrView.vue';
import { useI18n } from 'vue-i18n';
import Backend from '@/core/Services/Backend';
import useQrResult from '@/utils/useQrResult';
export default defineComponent({
    name: 'AuthQr',
    props: {
        // 授权额度
        amount: {
            type: String,
            required: true,
        },
        chainType: {
            type: String,
            required: true,
        },
        hash: {
            // 原生币种不需要授权
            type: String,
            required: true,
        },
        gasPrice: {
            type: String,
            required: false,
        },
        gasLimit: {
            type: String,
            required: false,
        },
        isNft: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    components: { QrView },
    emits: {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        success: (hash, chainType, qrId) => true,
        close: () => true,
    },
    setup(props, { emit }) {
        const { t } = useI18n();
        const qrCode = ref('');
        const qrId = ref('');
        const { startFetchResult } = useQrResult((hash, chainType) => {
            emit('success', hash, chainType, qrId.value);
        });
        onMounted(async () => {
            const { id, qr } = await Backend.getAuthQr(props.amount, props.chainType, props.hash, props.isNft, props.gasPrice, props.gasLimit);
            qrCode.value = qr;
            qrId.value = id;
            startFetchResult(id);
        });
        const emitClose = () => {
            emit('close');
        };
        return {
            t,
            qrCode,
            emitClose,
        };
    },
});
