export const openExplorerFortx = (chainType, tx) => {
    switch (chainType) {
        case 'eth':
            window.open(`https://etherscan.io/tx/${tx}`);
            break;
        case 'bsc':
            window.open(`https://bscscan.com/tx/${tx}`);
            break;
        case 'heco':
            window.open(`https://hecoinfo.com/tx/${tx}`);
            break;
        case 'okex':
            window.open(`https://www.oklink.com/okexchain/tx/${tx}`);
            break;
        case 'polygon':
            window.open(`https://polygonscan.com/tx/${tx}`);
            break;
        default:
            throw Error('not find for chainType');
    }
};
export const openExplorerForAddress = (chainType, address) => {
    switch (chainType) {
        case 'eth':
            window.open(`https://etherscan.io/address/${address}`);
            break;
        case 'bsc':
            window.open(`https://bscscan.com/address/${address}`);
            break;
        case 'heco':
            window.open(`https://hecoinfo.com/address/${address}`);
            break;
        case 'okex':
            window.open(`https://www.oklink.com/okexchain/address/${address}`);
            break;
        case 'polygon':
            window.open(`https://polygonscan.com/address/${address}`);
            break;
        default:
            throw Error('not find for chainType');
    }
};
