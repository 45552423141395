import { computed, ref } from 'vue';
// eslint-disable-next-line @typescript-eslint/no-explicit-any
function sliceArray(arr, size) {
    const arr2 = [];
    for (let i = 0; i < arr.length; i += size) {
        arr2.push(arr.slice(i, i + size));
    }
    return arr2;
}
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const useLocalPager = (data, pageSize = 10) => {
    const pageNumber = ref(1);
    const afterArray = sliceArray(data, pageSize);
    const currentArray = computed(() => afterArray[pageNumber.value - 1]);
    return {
        pageNumber,
        currentArray,
        total: data.length,
    };
};
export default useLocalPager;
