import { defineComponent, computed } from 'vue';
import { useI18n } from 'vue-i18n';
export default defineComponent({
    name: 'Pager',
    props: {
        total: {
            type: Number,
            required: true,
        },
        size: {
            type: Number,
            required: false,
            default: 10,
        },
        modelValue: {
            // 当前页从1开始
            type: Number,
            required: true,
        },
    },
    emits: ['change', 'update:modelValue'],
    setup(props, { emit }) {
        const { t } = useI18n();
        const pageCount = computed(() => Math.ceil(props.total / props.size));
        const prevHandler = () => {
            if (props.modelValue === 1) {
                return;
            }
            const val = props.modelValue - 1;
            emit('update:modelValue', val);
            emit('change', val);
        };
        const nextHandler = () => {
            if (props.modelValue === pageCount.value) {
                return;
            }
            const val = props.modelValue + 1;
            emit('update:modelValue', val);
            emit('change', val);
        };
        return {
            t,
            prevHandler,
            nextHandler,
            pageCount,
        };
    },
});
