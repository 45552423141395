import { defineComponent, getCurrentInstance } from 'vue';
export default defineComponent({
    name: 'BaseTableCol',
    props: {
        label: {
            // 表头
            type: String,
            required: true,
        },
        prop: {
            // 展示的属性key
            type: String,
            required: false,
            default: '',
        },
        noWrap: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    setup(props) {
        const instance = getCurrentInstance();
        if (!instance) {
            throw new Error('unexcepted: no instance');
        }
        const parent = instance.parent;
        if (!parent || !parent.data) {
            throw new Error('unexcepted: no parent');
        }
        const col = {
            label: props.label,
            prop: props.prop,
            id: `${Date.now()}${Math.random()}`,
            noWrap: props.noWrap,
            renderFunc: instance.proxy?.$slots.default || null,
        };
        // eslint-disable-next-line
        parent.data.cols.push(col);
    },
    render() {
        return null;
    },
});
